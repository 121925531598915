import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				OceanDream Charters
			</title>
			<meta name={"description"} content={"Відкрийте для себе найкращий досвід оренди яхт з OceanDream Charters. Плавання на розкішних яхтах, дослідження захоплюючих місць та створення незабутніх спогадів.\n"} />
			<meta property={"og:title"} content={"OceanDream Charters"} />
			<meta property={"og:description"} content={"Відкрийте для себе найкращий досвід оренди яхт з OceanDream Charters. Плавання на розкішних яхтах, дослідження захоплюючих місць та створення незабутніх спогадів.\n"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});